import React from 'react';
import { useEffect, useState } from 'react';
import { isAuth } from '../actions/auth'
import { useRouter } from 'next/router'
import { forgotpassword } from '../actions/auth'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from './ui/form';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { useForm } from 'react-hook-form';
import { Alert, AlertDescription } from './ui/alert';
import { AlertCircle, Loader2 } from 'lucide-react';

export default function PasswordReset({ setOpenRestoreEmail, setReset }: { setOpenRestoreEmail: (open: boolean) => void, setReset: (reset: boolean) => void }) {
  const router = useRouter()
  const [user, setUser] = useState({})
  const [alert, setAlert] = useState({ message: false, error: false, loading: false })
  const [email, setEmail] = useState<string | string[] | undefined>()

  const form = useForm()
  const { message, error, loading } = alert

  useEffect(() => {
    setUser(isAuth())
    setEmail(router.query.email)
  }, [router.query.email])

  const submit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    setAlert({ ...alert, message: false, error: false, loading: true })
    forgotpassword(email).then((result) => {
      if (result.error)
        return setAlert({ ...alert, message: false, error: result.error, loading: false })

      setAlert({ ...alert, message: result.message, error: false, loading: false })
    }).then(() => {
      setReset(false)
      setOpenRestoreEmail(true)
    })
  }

  const handleChange = _ => (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value)

  return (
    <div>
      <div className='mb-10'>
        <h2 className="text-[27px] tracking-tight font-semibold">Reestablecer contraseña</h2>
      </div>
      <div>
        <div>
          {error && <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              {error}
            </AlertDescription>
          </Alert>}
        </div>
      </div>
      <Form {...form}>
        <form onSubmit={submit}>
          <FormField
            control={form.control}
            name="login"
            render={() => <React.Fragment>
              <FormItem className='flex flex-col gap-1'>
                <FormLabel>Correo electronico <span className='text-primary'>*</span></FormLabel>
                <FormDescription className='m-0'>Ingresa tu correo electronico para recibir un enlace de recuperación y restablecer tu contraseña.</FormDescription>
                <FormControl>
                  <Input placeholder="ejemplo@gmail.com" value={email} onChange={handleChange('email')} className='py-[22px]' required autoFocus />
                </FormControl>
                <FormMessage />
              </FormItem>
            </React.Fragment>}
          />
          <Button type="submit" className='w-full mt-2 py-[22px]'>
            {loading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : 'Enviar enlace de recuperación'}
          </Button>
        </form>
      </Form>
    </div>
  )
}