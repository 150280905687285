import Login from "@/components/Login";
import PasswordReset from "@/components/PasswordReset";
import { RestoreEmailSentDialog } from "@/components/restorePassword/RestoreEmailSentDialog";
import { Button } from "@/components/ui/button";
import { ChevronLeft } from 'lucide-react';
import mixpanel from 'mixpanel-browser';
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

// Server side props, if query token and the agent is an embeded iphone web view, redirect to https://legacy.getventia.com/login?token=token

export async function getServerSideProps({ query, req }) {
  const { token, newVentia } = query || {}

  if (token && req.headers["user-agent"].includes("iPhone") && newVentia + "" !== "true") {
    return {
      redirect: {
        destination: `https://legacy.getventia.com/login?token=${token}`,
        permanent: false,
      },
    }
  }

  return {
    props: {},
  };
}

export default function Home() {
  const router = useRouter();

  const [usingMobile, setUsingMobile] = useState<boolean>(false)
  const [reset, setReset] = useState(router.query && router.query.forgotpassword == "true");
  const [openRestoreEmail, setOpenRestoreEmail] = useState(false);

  const query = router.query;
  const browser = typeof window !== 'undefined';

  useEffect(() => {
    const resetDef = query && query.forgotpassword == "true";

    if (resetDef) {
      setReset(true);
      const params = new URLSearchParams((query).toString())
      const queryString = params.toString();
      params.delete("forgotpassword");
      const path = `/login${queryString ? `?${queryString}` : ""}`;
      router.replace(path, "", { scroll: false });
    }
  }, [query.forgotpassword]);

  useEffect(() => {

    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search);
      const usingMobile = searchParams.get('usingMobile');

      mixpanel.track('Debug login', { usingMobile });

      setUsingMobile(!!usingMobile)
    };
  }, [query])

  return (
    <div className="flex w-full min-h-[100vh]">

      <Head>
        <title>Iniciar sesión en Ventia</title>
        <meta name="description" content="Accede a Ventia: tu plataforma de gestión y optimización de ventas. Mejora la productividad, controla tus ventas, y aprovecha todos los datos de tus clientes. Únete a más de 10.000 vendedores en LATAM que están incrementando sus ventas con Ventia"></meta>
      </Head>
      <div className="flex-1 flex justify-center items-center px-5">
        <div className="flex flex-col max-w-[460px] w-full">
          <div>
            <div className="mb-7">
              <Image src='/logo.png' width={110} height={20} className="object-contain" alt="image logo ventia" />
            </div>
          </div>
          {reset
            ? <PasswordReset setOpenRestoreEmail={setOpenRestoreEmail} setReset={setReset} />
            : <Login usingMobile={usingMobile} />}
          <div className="mt-5 flex justify-between">
            {!(browser && router && router.query && router.query.token)
              && <div className="flex justify-between w-full">
                <div>
                  <Button
                    variant="link"
                    onClick={() => setReset(!reset)}
                    className='text-[15px] p-0 m-0'>
                    {reset
                      ? <React.Fragment>
                        <ChevronLeft />
                        <span>Volver</span>
                      </React.Fragment>
                      : 'Olvidaste tu contraseña?'}
                  </Button>
                </div>
                <div>
                  <Link href="https://www.getventia.com/no-tengo-cuenta" className="text-[15px]">
                    <Button variant="link" className="m-0 p-0">
                      {!reset && 'No tienes cuenta?'}
                    </Button>
                  </Link>
                </div>
              </div>}
          </div>
        </div>
      </div>
      <RestoreEmailSentDialog
        open={openRestoreEmail}
        onClose={() => setOpenRestoreEmail(false)}
      />
      <div className="flex-1 bg-primary md:flex hidden px-5 bg-gradient-to-r to-primary from-[#0058b1]
      via-[#006ad4] animate-gradient-x"></div>
    </div>
  )
}