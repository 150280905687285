import useHandlerNotification from '@/hooks/useHandlerNotification';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import { AlertCircle, Loader2 } from 'lucide-react';
import mixpanel from 'mixpanel-browser';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { authenticate, isAuth, reloadtoken, setCookie, signin } from '../actions/auth';
import { BtnNextStep } from './buttons/BtnNextStep';
import InputPassword from './crm/Inputs/input-password';
import { ItemField } from './grupos/notification/DialogAddNewNotification';
import { Alert, AlertDescription } from './ui/alert';
import { Form, FormField } from './ui/form';
import { Input } from './ui/input';

const zSchemaLogin = z.object({
  email: z.string().email(),
  password: z.string().min(4, { message: 'La contraseña debe de contener por lo menos 4 caracteres' })
})

const defaultValues = {
  email: '',
  password: ''
}

const LoginPage = ({ usingMobile = false }: { usingMobile: boolean }) => {
  const router = useRouter();
  const { handleGenerateTokenFCM } = useHandlerNotification({ listenChanges: !!usingMobile })
  const [alertLogin, setAlertLogin] = useState({ message: "", error: "", loading: false });

  const contextForm = useForm({
    resolver: zodResolver(zSchemaLogin),
    defaultValues
  })

  useEffect(() => {
    const email = router?.query?.email as string;
    const password = router?.query?.password as string;
    contextForm.reset({ email: email || "", password: password || "" })
  }, [router?.query]);

  const handleSubmit = async (values: z.infer<typeof zSchemaLogin>) => {
    setAlertLogin({ ...alertLogin, loading: true })
    let fcmToken: any = null;

    if (!usingMobile) {
      fcmToken = await handleGenerateTokenFCM();
    }
    signin({ ...values, fcm_token: fcmToken })
      .then(async (res) => {
        if (res.error) { return setAlertLogin({ ...alertLogin, message: "", error: res.error, loading: false }) }
        
        setAlertLogin({
          ...alertLogin,
          message: "Inicio de sesión exitoso, redireccionando..",
          error: "",
          loading: true,
        });

        return authenticate(res, () => {
          if (router?.query?.returnurl && router.query.returnurl != "/" && typeof router.query.returnurl == "string") {
            router.push(router.query.returnurl);
          } else {
            router.push(_.get(res, "user.startPage") ?? "/");
          }
        });
      })
      .catch(() => {
        setAlertLogin({ ...alertLogin, message: "", error: "Error", loading: false });
      });
  };

  const browser = typeof window !== 'undefined';

  useEffect(() => {
    const { token } = router.query;

    mixpanel.track('Debug login', {
      token,
      browser,
      query: router.query,
      condition: token && browser
    });

    if (token && browser) {
      setCookie("token", token);
      setCookie("onApp", "true");

      reloadtoken().then((res) => {
        mixpanel.track('Debug login', {
          registered: !!res,
          response: res
        });

        if (!res || res.error) { router.push("/login") }

        authenticate(res, async () => {
          mixpanel.track('Debug login', {
            authenticate: true
          });

          router.push("/")
        });
      });
    }

    if (isAuth()) { router.push("/") }
  }, [router?.query]);

  const { error, loading, message } = alertLogin || {};

  if (browser && router?.query?.token) {
    return (
      <>
        <div className='w-full h-[100vh] flex justify-center items-center'>
          <Loader2 className="h-8 w-8 animate-spin text-primary" />
        </div>
      </>
    )
  }

  return (
    <>
      <div>
        <h2 className="text-[27px] font-semibold tracking-tight">Hola, bienvenido a Ventia 👋</h2>
        <p className="text-[17px] tracking-tight  font-normal text-neutral-500">Iniciar sesión</p>
      </div>
      <div>
        <div className='min-h-[50px] py-2'>
          {error && <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              {error}
            </AlertDescription>
          </Alert>}
        </div>
      </div>
      <Form {...contextForm}>
        <form onSubmit={contextForm.handleSubmit(handleSubmit)}>
          <FormField
            control={contextForm.control}
            name="email"
            render={({ field }) => (
              <ItemField label={<>Correo electronico <span className='text-primary'>*</span></>}>
                <Input
                  placeholder="ejemplo@gmail.com"
                  required className='py-[22px]'
                  type='email'
                  {...field}
                  autoFocus />
              </ItemField>
            )
            }
          />
          <FormField
            control={contextForm.control}
            name="password"
            render={({ field }) => (
              <ItemField label={<>Contraseña <span className='text-primary'>*</span></>} className='mt-7'>
                <InputPassword
                  placeholder="*************"
                  className='py-[22px] pr-[70px]'
                  autoComplete='off'
                  {...field}
                  required
                />
              </ItemField>
            )}
          />
          <BtnNextStep type='submit' variant={'default'} className='w-full mt-7 py-[22px]' hiddenArrow loading={loading} >
            Ingresar
          </BtnNextStep>
        </form>
      </Form>
    </ >
  )
};

export default LoginPage;